





















































import { Component, Provide, Vue } from "vue-property-decorator";
import {
  ApiPostDoLoginOld,
  ApiPostVerifyCode,
  ApiAddLoginDevice,
} from "@/apis";
import * as utils from "@/utils";
import { AppModule } from "@/store/modules/app";
import { breadcrumb } from "@/utils/breadcrumb";

@Component({
  name: "Login",
  components: {},
})
export default class Login extends Vue {
  captchaImg = "";
  captchaCode = "";
  @Provide() userName = "";
  @Provide() pwd = "";
  @Provide() picCode = "";

  async mounted() {
    await this.requestCaptcha();
    sessionStorage.clear();
  }
  created() {
    document.title = "中国进行性核上性麻痹协作平台";
  }
  //密码输入框
  isPassword = true;
  changePasswordState() {
    this.isPassword = !this.isPassword;
  }

  async requestCaptcha() {
    try {
      const res = await ApiPostVerifyCode();
      if (res.success) {
        utils.set("REQUEST-AUTH", res.data.auth);
        utils.set("REQUEST-JSESSIONID", res.data.jsessionid);
        this.captchaImg = "data:image/jpg;base64," + res.data.verify_code;
      }
    } catch (e) {
      console.log(e);
    }
  }

  refreshCaptcha() {
    this.requestCaptcha();
  }

  trim(val: string) {
    const reg = /(^\s*)|(\s*$)/g;
    return val.replace(reg, "");
  }

  async login() {
    if (this.userName && this.pwd && this.picCode) {
      try {
        const encryptPwd = await utils.encrypt(this.pwd);
        const result = await ApiPostDoLoginOld(
          this.trim(this.userName),
          encryptPwd,
          this.trim(this.picCode),
          utils.get("REQUEST-JSESSIONID")
        );
        if (result.success) {
          utils.set("auth", result.data.auth);
          utils.set("REQUEST-AUTH", "");
          utils.set("doctorLoginInfo", result.data);
          utils.set("userId", result.data.id);
          if (result.data.name) {
            utils.set("userName", result.data.name);
          }
          if (result.data.avater) {
            utils.set("headIcon", result.data.avater);
          }
          AppModule.ToggleFunctionGroupArray(result.data.menu_groups);
          //调用董哥接口
          this.addLoginDevice(result.data.id);
          //跳转到主页
          this.didLoginSuccess();
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      if (!this.userName) {
        this.$message.error("用户名不能为空！");
      } else if (!this.pwd) {
        this.$message.error("密码不能为空！");
      } else if (!this.picCode) {
        this.$message.error("验证码不能为空！");
      }
    }
  }
  addLoginDevice(id: string) {
    const param = {
      user_id: id,
      device_module: 5,
    };
    ApiAddLoginDevice(param).then((res) => {
      if (res.success) {
        res;
      }
    });
  }
  didLoginSuccess() {
    breadcrumb.push(this, "/home_liu", "首页", {}, true);
  }
}
